import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import EbookHero from "../components/ebook-hero"
import SEO from "../components/seo"
import Container from "../components/container"
import theme from "../consts/theme"
import { Link } from "gatsby"
import { getEbooks } from "../utils"

const SC = {}

const EbookEstudosMaisProdutivos = () => {
  const ebook = getEbooks("Mapa do Meta-aprendizado")

  return (
    <Layout>
      <SEO
        title={`[eBook] ${ebook.title}`}
        description={ebook.description}
        canonical={ebook.url.substring(1)}
        author="Apetrecho Digital"
      />
      <EbookHero
        cover={ebook.cover}
        title={`Aprenda a fazer
          <br />
          seu próprio mapa
          <br />
          do meta-aprendizado!`}
        description={`Baixe agora o <b>passo a passo</b> para criar
          <br />
          seu mapa do meta-aprendizado e um<br />
          <b>template</b> para imprimir e preencher!`}
        titlePrimaryColor="#fff"
        titleSecondaryColor="#fff"
        descriptionColor="#fff"
        leftBgColor={theme.colors.SOFT_SKILLS}
        rightBgColor="#fff"
        ebookTag={ebook.mailchimpTag}
        inputBgColor="#FFFFFF22"
      />
      <SC.Content
        isFlex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <SC.Description>
          Aprenda como fazer seu próprio mapa do{" "}
          <Link to="/aprender-a-aprender-meta-aprendizado/">
            meta-aprendizado
          </Link>{" "}
          respondendo às perguntas:
        </SC.Description>
        <SC.QuestionsWrapper>
          <SC.Question>Por quê?</SC.Question>
          <SC.Question>O quê?</SC.Question>
          <SC.Question>Como?</SC.Question>
        </SC.QuestionsWrapper>
        <SC.Description>
          Descubra a melhor forma de aprender <b>tudo o que você quiser</b>,
          através do meta-aprendizado: uma análise do processo de aprendizagem.{" "}
        </SC.Description>
      </SC.Content>
    </Layout>
  )
}

SC.Content = styled(Container)`
  padding: 80px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
    padding: 40px 25px;
  }
`

SC.Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-size: 24px;
  text-align: center;
  width: 60%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
    font-size: 20px;
  }

  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.SOFT_SKILLS};
    text-decoration-thickness: 2.8px;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.colors.SOFT_SKILLS};
      text-shadow: 0 0 1px ${({ theme }) => theme.colors.SOFT_SKILLS};
    }
  }
`

SC.QuestionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  margin: 40px 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }
`

SC.Question = styled.div`
  background-color: ${({ theme }) => theme.colors.SOFT_SKILLS};
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  font-size: 22px;
  font-weight: 600;
  border-radius: ${({ theme }) => theme.sizes.ROUNDED_BORDER_RADIUS};
  color: #fff;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 90px;
    height: 90px;
    text-align: center;
    font-size: 18px;
  }
`

export default EbookEstudosMaisProdutivos
